import http from "@/utils/request";

//超期库存列表
export function getExceedStockList(data){
    return http({
        url:'/exceedStockList/exceedInvageList.htm',
        method:'post',
        data
    })
}
//超期库存列表
export function exportStock(data){
    return http({
        url:'/exceedStockList/exceedInvageListExport.htm',
        method:'post',
        responseType: 'blob',
        data
    })
}
//超期库申请
export function saveAplly(data){
    return http({
        url:'/exceedStockList/submitApply.nd',
        method:'post',
        data
    })
}